import {
  convertCryptoToDollars,
  convertToDollarAmount,
  extractCollectionLink,
} from '..'
import {
  getMarketplaceLinkForCollection,
  Marketplace,
} from '../reports/marketplace_utils'
import {
  ReportType,
  getStatusLabel,
  HASURA_MAX_INT,
  CLASSIFICATION_TO_LABEL,
} from '../constants'
import { getBestMatchFlaggedUrl, getDoppelLink } from '../reports/report_utils'
import {
  getLastReportedAt,
  getLastResolvedAt,
} from '@/components/web2/audit_logs/audit_log_utils'
import {
  WEB2_ORDERED_COLUMNS,
  NUM_INDIVID_NFTS_TO_EXPORT,
  PROTOCOLS,
} from './report_csv_constants'
import {
  flattenDomainData,
  flattenSocialMediaData,
  flattenSocialMediaPost,
} from './report_csv_utils'
import {
  reorderRowKeys,
  prepareTextForCsv,
  getTags,
  getTopMatch,
} from './general_csv_utils'

export const processReportRowsForExport = (
  reportType: ReportType,
  rawRows,
  juicedMetrics: boolean,
  isEmployeeView: boolean,
) => {
  let rows
  switch (reportType) {
    case ReportType.NFTS:
      rows = processWeb3RowsForExport(rawRows)
      break
    case ReportType.DARK_WEB:
      rows = processDarkWebRowsForExport(rawRows)
      break
    case ReportType.ECOMMERCE:
      rows = processEcommerceRowsForExport(rawRows)
      break
    case ReportType.SOCIAL_MEDIA:
      rows = processSocialMediaRowsForExport(rawRows)
      break
    case ReportType.MOBILE_APPS:
      rows = processMobileAppRowsForExport(rawRows)
      break
    default:
      rows = processWeb2RowsForExport(rawRows, juicedMetrics).map((row) =>
        reorderRowKeys(row, WEB2_ORDERED_COLUMNS),
      )
      break
  }

  rows.map((row) => {
    Object.keys(row).forEach((key) => {
      if (typeof row[key] === 'string') {
        row[key] = prepareTextForCsv(row[key])
      }
    })
  })

  rows.map((row) => {
    row['tags'] = getTags(row['tags'] ?? [], isEmployeeView)
  })

  return rows
}

export function processWeb3RowsForExport(rawRows) {
  return rawRows.map((rawRow) => {
    const baseRow = rawRow.original ? { ...rawRow.original } : { ...rawRow }
    const firstMatch = baseRow.collection_reports_to_matches?.[0]?.match || null

    const processedRow = {
      id: `https://app.doppel.com/nfts/${baseRow.id}`,
      status: getStatusLabel(baseRow.status, ReportType.NFTS),
      doppel_link: getDoppelLink(baseRow.id, baseRow.platform?.product),
      max_similarity_score: baseRow.max_similarity_score,
      notes: baseRow.notes,
      last_reported_at: getLastReportedAt(baseRow.collection_report_changes),
      last_resolved_at: getLastResolvedAt(baseRow.collection_report_changes),
      timestamp: firstMatch?.created_at,
      delist_detected: firstMatch?.delist_detected_at,
      original_collection_url: extractCollectionLink(
        baseRow.original_collection?.collection_slug,
      ),
      original_collection_slug: baseRow.original_collection?.collection_slug,
      flagged_collection_url: extractCollectionLink(
        baseRow.flagged_collection?.collection_slug,
      ),
      flagged_collection_slug: baseRow.flagged_collection?.collection_slug,
      flagged_collection_name: baseRow.flagged_collection?.collection_name,
      flagged_collection_image_url: baseRow.flagged_collection?.image_url,
      flagged_collection_banner_image_url: baseRow.flagged_collection?.banner_image_url,
      priority_score: baseRow.importance_score,
      num_items: baseRow.flagged_collection?.num_items,
      num_matches: baseRow.collection_reports_to_matches_aggregate?.aggregate?.count,
      estimated_value: convertToDollarAmount(
        baseRow.collection_reports_to_matches_aggregate?.aggregate?.sum?.usd_price,
      ),
      collection_volume_traded_usd: convertCryptoToDollars(
        baseRow.flagged_collection?.volume_traded_opensea,
        baseRow.flagged_collection?.chain,
      ),
      flagged_nft_volume_traded_usd: convertToDollarAmount(
        baseRow.collection_reports_to_matches_aggregate?.aggregate?.sum?.usd_volume,
      ),
      floor_price_usd: convertCryptoToDollars(
        baseRow.flagged_collection?.floor_price_opensea,
        baseRow.flagged_collection?.chain,
      ),
      flag_reason: firstMatch?.asset?.type,
      flagged_collection_rarible_url: getMarketplaceLinkForCollection(
        Marketplace.Rarible,
        baseRow,
        firstMatch,
        baseRow.flag_reason !== undefined,
      ),
      flagged_creator_address: firstMatch?.flagged_nft?.creator_address,
    }

    for (let i = 0; i < NUM_INDIVID_NFTS_TO_EXPORT; i++) {
      let match = undefined
      if (baseRow.collection_reports_to_matches.length > i) {
        match = baseRow.collection_reports_to_matches[i].match
      }
      processedRow[`flagged_collection_matched_nft_${i + 1}_title`] =
        match?.flagged_nft?.title
      processedRow[`flagged_collection_matched_nft_${i + 1}_url`] =
        match?.flagged_nft?.marketplace_link
    }

    return processedRow
  })
}

export function processWeb2RowsForExport(rawRows, juiced) {
  const processedRow = (rawRow) => {
    const baseRow = { ...(rawRow.original || rawRow) }
    const { spoofing_entity: spoofingEntity, spoof_matches: spoofMatches } = baseRow
    const domain = getBestMatchFlaggedUrl(spoofMatches) || baseRow.flagged_url || null

    const topMatch = spoofMatches ? spoofMatches[0] : null

    const cleanRowBase = {
      report_status: getStatusLabel(baseRow.report_status, ReportType.DOMAINS) || null,
      domain: domain,
      doppel_link: getDoppelLink(baseRow.id, baseRow.platform_subtype?.product),
      screenshot_url: baseRow.screenshot_url,
      flagged_url: baseRow.flagged_url,
      unsanitized_url: baseRow.unsanitized_url,
      created_at: baseRow.created_at,
      notes: baseRow.notes,
      source: baseRow.source,
      last_reported_at: getLastReportedAt(baseRow.collection_report_changes),
      last_resolved_at: getLastResolvedAt(baseRow.collection_report_changes),
      score: baseRow.score,
      uploader: baseRow.uploader?.name || null,
      is_customer_sourced: baseRow.uploader?.is_internal
        ? baseRow.is_customer_sourced ?? false
        : 'N/A',
      platform: baseRow.platform_subtype?.name || null,
      brand: spoofingEntity?.entity_name || null,
      brand_url: spoofingEntity?.entity_url || null,
      classification: topMatch
        ? CLASSIFICATION_TO_LABEL[topMatch.classification]
        : null,
      ...flattenDomainData(baseRow),
      ...globalRowsForExport(baseRow),
    }

    if (juiced) {
      return spoofMatches.flatMap((match) => {
        const classification = CLASSIFICATION_TO_LABEL[match.classification] || null
        const matchUrl = match.full_url?.url || domain || null

        return PROTOCOLS.map((protocol) => ({
          ...cleanRowBase,
          match_url: matchUrl,
          classification: classification,
          url: `${protocol}${matchUrl}`,
        }))
      })
    } else {
      return [cleanRowBase]
    }
  }

  return juiced ? rawRows.flatMap(processedRow) : rawRows.map(processedRow).flat()
}

export function processSocialMediaRowsForExport(rawRows) {
  return rawRows.map((rawRow) => {
    const baseRow = { ...(rawRow.original || rawRow) }
    const { spoofing_entity: spoofingEntity = {}, spoof_matches: spoofMatches = [] } =
      baseRow
    const topMatch = (spoofMatches && spoofMatches[0]) || null

    const processedRow = {
      report_status: getStatusLabel(baseRow.report_status, ReportType.DOMAINS) || null,
      type: baseRow.type,
      domain: getBestMatchFlaggedUrl(spoofMatches) || baseRow.flagged_url || null,
      doppel_link: getDoppelLink(baseRow.id, baseRow.platform_subtype?.product),
      screenshot_url: baseRow.screenshot_url,
      flagged_url: baseRow.flagged_url,
      unsanitized_url: baseRow.unsanitized_url,
      created_at: baseRow.created_at,
      notes: baseRow.notes,
      source: baseRow.source,
      last_reported_at: getLastReportedAt(baseRow.collection_report_changes),
      last_resolved_at: getLastResolvedAt(baseRow.collection_report_changes),
      score: baseRow.score,
      uploader: baseRow.uploader?.name || null,
      platform: baseRow.platform_subtype?.name || null,
      brand: spoofingEntity.entity_name || null,
      brand_url: spoofingEntity.entity_url || null,
      classification: topMatch
        ? CLASSIFICATION_TO_LABEL[topMatch.classification]
        : null,
      ...flattenSocialMediaData(baseRow, topMatch),
      ...globalRowsForExport(baseRow),
    }

    flattenSocialMediaPost(baseRow, topMatch)
    return processedRow
  })
}

export function processDarkWebRowsForExport(rawRows) {
  return rawRows.map((rawRow) => {
    const baseRow = rawRow.original ? { ...rawRow.original } : { ...rawRow }
    const {
      organization,
      spoofing_entity: spoofingEntity,
      spoof_matches: spoofMatches = [],
      spoofing_reports_to_tags: tags,
    } = baseRow
    const topMatch = spoofMatches[0] || null
    const darkWeb = topMatch?.dark_web || null
    const tagNames = tags.map((tag) => tag.tag_name).toString()

    const processedRow = {
      report_status: getStatusLabel(baseRow.report_status, ReportType.DARK_WEB),
      type: baseRow.type,
      doppel_link: getDoppelLink(baseRow.id, baseRow.platform_subtype?.product),
      unsanitized_url: baseRow.unsanitized_url,
      created_at: baseRow.created_at,
      notes: baseRow.notes,
      source: baseRow.source,
      last_reported_at: getLastReportedAt(baseRow.collection_report_changes),
      last_resolved_at: getLastResolvedAt(baseRow.collection_report_changes),
      score: baseRow.score,
      platform: baseRow.platform_subtype?.name || null,
      organization_name: organization.name,
      entity_name: spoofingEntity.entity_name,
      entity_url: spoofingEntity.entity_url,
      classification: topMatch
        ? CLASSIFICATION_TO_LABEL[topMatch.classification]
        : null,
      crawled_at: darkWeb?.crawled_at || null,
      domain: darkWeb?.domain || null,
      email: darkWeb?.email || null,
      ip: darkWeb?.ip || null,
      leak_name: darkWeb?.leak_name || null,
      location: darkWeb?.location || null,
      network: darkWeb?.network || null,
      password: darkWeb?.password || null,
      password_type: darkWeb?.password_type || null,
      risk_score: darkWeb?.risk_score || null,
      title: darkWeb?.title || null,
      uri: darkWeb?.uri || null,
      url: darkWeb?.url || null,
      languages: darkWeb?.languages || null,
      body: darkWeb?.body || null,
      translated_title: darkWeb?.translated_title || null,
      translated_body: darkWeb?.translated_body || null,
      additional_data: darkWeb ? JSON.stringify(darkWeb.additional_data) : null,
      tag_names: tagNames,
    }

    return processedRow
  })
}

export function processEcommerceRowsForExport(rawRows) {
  return rawRows.map((rawRow) => {
    const baseRow = rawRow.original ? { ...rawRow.original } : { ...rawRow }
    const topMatch = baseRow.spoof_matches ? getTopMatch(baseRow.spoof_matches) : null
    const ecommerceListing = topMatch?.ecommerce_listing || null

    const processedRow = {
      report_status: getStatusLabel(baseRow.report_status, ReportType.ECOMMERCE),
      id: baseRow.id,
      doppel_link: getDoppelLink(baseRow.id, baseRow.platform_subtype?.product),
      organization: baseRow.organization.name,
      original_entity_id: baseRow.original_entity_id,
      screenshot_url: baseRow.screenshot_url,
      spoof_status: baseRow.spoof_status,
      report_screenshots: baseRow.report_screenshots,
      flagged_url: baseRow.flagged_url,
      unsanitized_url: baseRow.unsanitized_url,
      created_at: baseRow.created_at,
      notes: baseRow.notes,
      source: baseRow.source,
      website_content: baseRow.website_content,
      last_reported_at: getLastReportedAt(baseRow.collection_report_changes),
      last_resolved_at: getLastResolvedAt(baseRow.collection_report_changes),
      estimated_usd_price: baseRow.estimated_usd_price,
      score: baseRow.score,
      external_source: baseRow.external_source,
      spoofing_entity: baseRow.spoofing_entity,
      domain_data: baseRow.domain_data,
      first_domain_data: baseRow.first_domain_data,
      social_media_data: baseRow.social_media_data,
      social_media_post: baseRow.social_media_post,
      approvals: baseRow.approvals,
      spoof_matches: baseRow.spoof_matches,
      uploader: baseRow.uploader,
      platform: baseRow.platform_subtype?.name,
      title: ecommerceListing?.title,
      description: ecommerceListing?.description,
      price: ecommerceListing?.price,
      num_units:
        ecommerceListing?.num_units === HASURA_MAX_INT
          ? '∞'
          : ecommerceListing?.num_units,
      seller_name: ecommerceListing?.seller_name,
      shop_name: ecommerceListing?.shop_name,
      listing_time: ecommerceListing?.external_created_at,
      listing_id: ecommerceListing?.external_id,
      telegram_link: ecommerceListing?.telegram_info?.inviteLink,
      ...globalRowsForExport(baseRow),
    }

    return processedRow
  })
}

export function processMobileAppRowsForExport(rawRows) {
  return rawRows.map((rawRow) => {
    const baseRow = rawRow.original ? { ...rawRow.original } : { ...rawRow }

    const topMatch = baseRow.spoofMatches ? getTopMatch(baseRow.spoofMatches) : null
    const mobileApp = topMatch ? topMatch.mobile_app : null

    const processedRow = {
      report_status: getStatusLabel(baseRow.report_status, ReportType.MOBILE_APPS),
      type: baseRow.type,
      doppel_link: getDoppelLink(baseRow.id, baseRow.platform_subtype?.product),
      unsanitized_url: baseRow.unsanitized_url,
      created_at: baseRow.created_at,
      notes: baseRow.notes,
      source: baseRow.source,
      last_reported_at: getLastReportedAt(baseRow.collection_report_changes),
      last_resolved_at: getLastResolvedAt(baseRow.collection_report_changes),
      score: baseRow.score,
      platform: baseRow?.platform_subtype?.name || null,
      app_id: mobileApp?.app_id || null,
      bundle_id: mobileApp?.bundle_id || null,
      developer_id: mobileApp?.developer_id || null,
      description: mobileApp?.description || null,
      developer_name: mobileApp?.developer_name || null,
      external_created_at: mobileApp?.external_created_at,
      link: mobileApp?.link || null,
      screenshot_url: mobileApp?.screenshot_url || null,
      thumbnail_url: mobileApp?.thumbnail_url || null,
      title: mobileApp?.title || null,
      ...globalRowsForExport(baseRow),
    }

    return processedRow
  })
}

const globalRowsForExport = (baseRow) => ({
  tags: baseRow?.report_tags,
})
