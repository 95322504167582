import { ENFORCEMENT_STATUS, DomainPlatform } from '../constants'
import { extractDiscordIds, extractSubreddit } from '../string_utils'

export function flattenDomainData(baseRow) {
  const {
    country_code = null,
    hosting_provider = null,
    ip_address = null,
    registrar_name: domain_registrar = null,
    contact_email = null,
  } = baseRow['first_domain_data'][0]?.full_url?.domain_data ||
  baseRow['domain_data'] ||
  {}

  return {
    country_code,
    hosting_provider,
    ip_address,
    domain_registrar,
    contact_email,
  }
}

export function flattenSocialMediaData(baseRow, topMatch) {
  const {
    platform_name: platform = null,
    slug: username = null,
    num_followers = null,
    profile_description = null,
    profile_display_name = null,
  } = topMatch?.social_media_data || baseRow['social_media_data'] || {}

  return {
    platform,
    username,
    num_followers,
    profile_description,
    profile_display_name,
  }
}

export function flattenSocialMediaPost(baseRow, topMatch) {
  const {
    full_text: post_text = null,
    url: post_url = null,
    num_upvotes: post_num_upvotes = null,
    platform: post_platform = null,
  } = topMatch?.social_media_post || baseRow['social_media_post'] || {}

  let post_channel = null
  if (post_platform === 'discord') {
    post_channel = extractDiscordIds(post_url)?.serverId
  } else if (post_platform === 'reddit') {
    post_channel = extractSubreddit(post_url)
  }

  const post_extra_urls =
    topMatch?.social_media_data?.profile_display_name ||
    baseRow['social_media_data']?.profile_display_name ||
    null

  return {
    post_text,
    post_url,
    post_num_upvotes,
    post_platform,
    post_channel,
    post_extra_urls,
  }
}

// Do not use enforcement request data right now - too much noise
export function flattenEnforcementRequests(row: Record<string, any>): void {
  const enforcementRequests = row['enforcement_requests'] || []
  const frequencyByPlatformStatus = new Map<string, number>()

  enforcementRequests.forEach((request) => {
    const { platform, status } = request
    if (!Object.values(ENFORCEMENT_STATUS).includes(status)) {
      return
    }
    const platformKey = Object.values(DomainPlatform).includes(platform)
      ? platform
      : 'OTHER'

    const platformStatusKey = `${platformKey}_${status}`
    const count = (frequencyByPlatformStatus.get(platformStatusKey) || 0) + 1
    frequencyByPlatformStatus.set(platformStatusKey, count)
  })

  for (const platform of [...Object.values(DomainPlatform), 'OTHER']) {
    for (const status of Object.values(ENFORCEMENT_STATUS)) {
      const key = `${platform}_${status}`
      row[`enforcements_for_${key}`] = frequencyByPlatformStatus.get(key) || 0
    }
  }
  row['total_enforcement_requests'] = enforcementRequests.length
}
